import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { Arrow } from 'app/components/Common/Arrow'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import KeyboardControls from 'app/utils/SliderKeyboardControls'
import { KeenSliderPlugin, useKeenSlider } from 'keen-slider/react'
import { uniq } from 'lodash'
import React, { memo, useState } from 'react'

import { Props as RoomProps, Room } from './Room'

const AdaptiveHeight: KeenSliderPlugin = (slider) => {
  function updateHeight() {
    slider.container.style.height =
      slider.slides[slider.track.details.rel]?.offsetHeight + 'px'
  }
  slider.on('created', updateHeight)
  slider.on('slideChanged', updateHeight)
}

export interface Props {
  rooms: RoomProps[]
}

export const MeetingRooms = memo(function MeetingRooms({ rooms }: Props) {
  if (rooms.length < 1) {
    return null
  }

  const [currentSlide, setCurrentSlide] = useState(0)
  const [opacities, setOpacities] = useState<number[]>([])

  const [sliderRef, instanceRef] = useKeenSlider(
    {
      drag: false,
      slides: rooms.length,
      loop: true,
      animationEnded(s) {
        setCurrentSlide(s.track.details.rel)
      },
      detailsChanged(s) {
        const new_opacities = s.track.details.slides.map(
          (slide: any) => slide.portion,
        )
        setOpacities(new_opacities)
      },
      breakpoints: {
        '(max-width: 1023px)': {
          drag: true,
          loop: true,
          slides: {
            perView: 1.08,
            spacing: 20,
          },
        },
      },
    },
    [AdaptiveHeight, KeyboardControls],
  )

  return (
    <Container>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <Slider ref={sliderRef}>
          {uniq(rooms).map((item, index) => (
            <Room
              className={`keen-slider__slide${
                currentSlide === index ? ' active' : ''
              }`}
              key={index}
              style={{ opacity: opacities[index] }}
              {...item}
            />
          ))}
        </Slider>

        {rooms && rooms.length > 1 ? (
          <>
            <Media greaterThanOrEqual="ipadHorizontal">
              <Arrows row>
                <Arrow
                  onClick={(e) =>
                    e.stopPropagation() || instanceRef.current?.prev()
                  }
                />
                <Arrow
                  direction="R"
                  onClick={(e) =>
                    e.stopPropagation() || instanceRef.current?.next()
                  }
                />
              </Arrows>
            </Media>

            <Media lessThan="ipadHorizontal">
              <Dots dial={5} row>
                {rooms.map((_item, index) => (
                  <Dot
                    key={index}
                    className={currentSlide === index ? 'active' : undefined}
                    onClick={() => {
                      instanceRef.current?.moveToIdx(index)
                    }}
                  />
                ))}
              </Dots>
            </Media>
          </>
        ) : null}
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  padding-bottom: 12.0625rem;
  position: relative;

  @media (max-width: 1023px) {
    background: ${({ theme }) => theme.colors.variants.neutralLight2};
    padding: 6.25rem 1rem 0;
  }
`

const Slider = styled.div`
  display: flex;
  outline: none;
  position: relative;
  &[data-keen-slider-moves] {
    a {
      pointer-events: none;
    }
  }

  @media (max-width: 1023px) {
    height: auto !important;
    overflow: visible !important;
  }
`

const Arrows = styled(FlexBox)`
  position: absolute;
  bottom: 3.9375rem;
  left: 50%;
  transform: translateX(-50%);

  > div {
    margin: 0 0.375rem;
  }
`

const Dots = styled(FlexBox)`
  margin-top: 1.625rem;
`

const Dot = styled.div`
  width: 0.625rem;
  height: 0.625rem;
  border: 0.125rem solid ${({ theme }) => theme.colors.variants.neutralLight1};
  border-radius: 50%;
  cursor: pointer;
  margin: 0 0.375rem;
  position: relative;
  &.active {
    background: ${({ theme }) => theme.colors.variants.secondaryLight};
    border-color: transparent;
  }
`
