import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'

export interface Props {
  languageCode: string
  pax?: number
  size?: number
}

export const Info = memo(function Info({ languageCode, pax, size }: Props) {
  if (!pax || !size) {
    return null
  }

  return (
    <Container row>
      {size ? (
        <Size>
          <Number>{size}</Number>
          <Label>{useVocabularyData('mq', languageCode)}</Label>
        </Size>
      ) : null}
      {pax ? (
        <Pax>
          <Number>{pax}</Number>
          <Label>{useVocabularyData('pax', languageCode)}</Label>
        </Pax>
      ) : null}
    </Container>
  )
})

const Container = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  padding: 2rem 2.75rem;
  position: absolute;
  top: 0;
  right: 0;
  text-align: center;
  text-transform: uppercase;
  z-index: 2;

  > div {
    &:nth-of-type(2) {
      margin-left: 4.625rem;
    }
  }

  @media (max-width: 1023px) {
    padding: 1.125rem 2.6875rem;
    right: auto;
    left: 50%;
    transform: translate(-50%, -50%);

    > div {
      display: flex;
      align-items: center;
      &:nth-of-type(2) {
        margin-left: 1.4375rem;
      }
    }
  }
`

const Pax = styled.div``

const Size = styled.div``

const Number = styled.div`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  font-weight: 500;
  letter-spacing: 0.1875rem;
  line-height: 2.5rem;

  @media (max-width: 1023px) {
    font-size: 1.25rem;
    letter-spacing: 0;
    line-height: 1.5rem;
    margin-right: 0.375rem;
    transform: translateY(0.0625rem);
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark5};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 0.0375rem;
  line-height: 0.9375rem;
`
