import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Download } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

import { Info } from './Info'
import { Layout, Props as LayoutProps } from './Layout'

export interface Props {
  className?: string
  description?: string
  image?: ImageProps
  layouts?: LayoutProps[]
  languageCode: string
  planimetry?: string
  pax?: number
  quotationPageURL?: string
  size?: number
  style?: {}
  title?: string
}

export const Room = memo(function Room({
  className,
  description,
  image,
  layouts,
  languageCode,
  pax,
  planimetry,
  quotationPageURL,
  size,
  style,
  title,
}: Props) {
  if (!title) {
    return null
  }

  return (
    <Container className={className} style={style} row stretch wrap>
      <LeftSide dial={7}>
        {title ? <Title>{title}</Title> : null}
        {description ? (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        ) : null}
        {layouts && layouts.length > 0 ? (
          <Layouts row wrap>
            {layouts.map((item, index) => (
              <Layout key={index} {...item} />
            ))}
          </Layouts>
        ) : null}
        {planimetry || quotationPageURL ? (
          <Buttons dial={4} row wrap>
            {quotationPageURL ? (
              <Button
                label={useVocabularyData('ask-for', languageCode)}
                URL={quotationPageURL}
                variant="primary"
              />
            ) : null}
            {planimetry ? (
              <Planimetry href={planimetry} rel="noopener" target="_blank">
                <Download />
                {useVocabularyData('download-the-map', languageCode)}
              </Planimetry>
            ) : null}
          </Buttons>
        ) : null}
      </LeftSide>
      <RightSide>
        <Info languageCode={languageCode} pax={pax} size={size} />

        {image ? (
          <LazyLoadComponent>
            <Image {...image} />
          </LazyLoadComponent>
        ) : null}
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  width: 100% !important;
  height: max-content !important;
  position: absolute;
  top: 0;
  left: 0;
  &.active {
    z-index: 2;
  }

  @media (min-width: 1024px) {
    transform: none !important;
  }

  @media (max-width: 1023px) {
    opacity: 1 !important;
    position: relative;
    top: auto;
    left: auto;
  }
`

const LeftSide = styled(FlexBox)`
  width: 50%;
  padding: 0 8.194vw 2.5rem 11.944vw;

  @media (max-width: 1199px) {
    padding-right: 3rem;
    padding-left: 2rem;
  }

  @media (max-width: 1023px) {
    width: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    order: 2;
    padding: 2.5625rem 2rem 2.875rem;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  line-height: 2.75rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark5};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 2rem;
  margin-top: 1.25rem;
`

const Layouts = styled(FlexBox)`
  margin-top: 2.5rem;

  @media (max-width: 1023px) {
    margin-top: 1.25rem;
  }
`

const Buttons = styled(FlexBox)`
  margin-top: 3.75rem;

  a {
    padding: 0 3rem;
    &:nth-of-type(2) {
      margin-left: 3.5rem;
      padding: 0;
    }
  }

  @media (max-width: 767px) {
    a {
      width: 100%;
      padding: 0 2rem;
      &:nth-of-type(2) {
        margin: 1.25rem 0 0;
        padding: 0;
      }
    }
  }
`

const Planimetry = styled.a`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.variants.primaryLight};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.0875rem;
  line-height: 1.3125rem;
  text-transform: uppercase;
  &:hover {
    text-decoration: underline;
  }

  svg {
    width: 1.25rem;
    height: auto;
    fill: ${({ theme }) => theme.colors.variants.primaryLight};
    margin-right: 0.75rem;
  }

  @media (max-width: 767px) {
    justify-content: center;
  }
`

const RightSide = styled.div`
  width: 50%;
  height: 70vh;
  max-height: 39.375rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight1};
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    width: 100%;
    height: auto;
    padding-bottom: 63%;
  }
`
