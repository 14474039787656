import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  icon?: string
  label?: string
}

export const Layout = memo(function Layout({ icon, label }: Props) {
  if (!icon || !label) {
    return null
  }

  return (
    <Container dial={4} row>
      {icon ? <Icon src={icon} alt={label} width="40" height="40" /> : null}
      {label ? <Label>{label}</Label> : null}
    </Container>
  )
})

const Container = styled(FlexBox)`
  width: 50%;
  margin-top: 1.25rem;
  padding-right: 1.25rem;

  @media (max-width: 767px) {
    width: 100%;
    margin-right: 0;
  }
`

const Icon = styled.img`
  width: 1.75rem;
  height: auto;
  max-height: 1.25rem;
  margin-right: 0.625rem;
`

const Label = styled.div`
  width: calc(100% - 2.375rem);
  color: ${({ theme }) => theme.colors.variants.neutralDark5};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 0.0375rem;
  line-height: 1.125rem;
  text-transform: uppercase;
`
